import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

export default function Education(){
    return (
        <>
            <Container>
                <div className="section">
                    <h1>Education</h1>
                </div>
                <Container>
                    <Row>
                        <Col md={6}>
                            <p>
                                Training and education have become the largest part of our work. While at our core, we are
                                developers, we have spent so much time learning and teaching that it has become a natural
                                outlet. Our Principal, <a href="https://www.frankmoley.com" target="_blank">Frank Moley</a>,
                                uses the phrase Never Stop Learning on an almost daily basis. We are more than happy to
                                help you with all your training needs.
                            </p>
                            <p>
                                Take a look at some of the topics we cover, if you aren't seeing what you would like,
                                reach out to us. We only teach what we know.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Accordion flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Spring Framework</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            The Spring Framework for Java is one of the most popular development frameworks
                                            for the JVM. Many Java shots leverage Spring to do their work, especially using
                                            Spring Boot.
                                        </p>
                                        <p>
                                            We have extensive experience with Spring-Data, Spring-Security, Spring-MVC and
                                            of course Spring Boot. We have an 8 hour introduction to Spring Course or can design
                                            something to meet your individual needs
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Kubernetes</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Our experiences building operators and leveraging kubernetes as an application
                                            runtime give us insights to core development needs for kubernetes itself. We can
                                            help your development team understand kubernetes concepts, building applications
                                            targeting the runtime, and general development tasks with this powerful runtime.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Microservices/Cloud Native</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            A large majority of our work, and trainings, are in the microservices and
                                            cloud native spaces. As such, we offer trainings on microservices architectures,
                                            domain driven design, design patterns, cloud native development, and of course
                                            can create custom content to fit your needs.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Java</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            While much of our Java based trainings relate to using the Spring Framework,
                                            we are not limited to just Spring. We also focus on various aspects of Java
                                            development from teaching the core language to leveraging tools like Maven. We
                                            also offer courses in Quarkus, a growing framework.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}