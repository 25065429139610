import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import Logo from "../../assets/logo.svg"
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import "./Header.css"
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    let core = "frank"
    let adj = "m3computing"
    event.preventDefault()
    window.location.href="mailto:" + core + "@" + adj + ".com";
};

export default function Header() {
    return (<>
        <Navbar variant="dark" fixed="top" collapseOnSelect expand="lg">
            <Container>
                <NavbarBrand href="#">
                    <img src={Logo}
                         height="30"
                         className="d-inline-block align-top"
                    />{' '}
                    M3 Computing
                </NavbarBrand>
                <Navbar.Toggle aria-controls="site-navbar" />
                <NavbarCollapse id="site-navbar">
                    <Nav className="me-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#consulting">Consulting</Nav.Link>
                        <Nav.Link href="#development">Development</Nav.Link>
                        <Nav.Link href="#education">Education</Nav.Link>
                    </Nav>
                    <Nav className="justify-content-end">
                        <Button

                            className="navbar-button"
                            variant="dark"
                            onClick={handleClick}>
                            Contact Us
                        </Button>
                    </Nav>
                </NavbarCollapse>
            </Container>
        </Navbar>
    </>)
}
