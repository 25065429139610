import React from "react";
import Container from "react-bootstrap/Container";
import "./Footer.css"
import Button from "react-bootstrap/Button";

const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    let core = "frank"
    let adj = "m3computing"
    event.preventDefault()
    window.location.href="mailto:" + core + "@" + adj + ".com";
};

export default function Footer()  {
    return (
        <>
            <Container>
                <h1>Let us help</h1>
                <p>
                    We are ready to help you with all your needs. Please reach out to us and schedule time today to go
                    over your needs. <Button

                    className="navbar-button"
                    variant="dark"
                    onClick={handleClick}>
                    Contact Us
                </Button>
                </p>
            </Container>
            <footer>
                <Container>
                    <div className="text-center">
                        <p>&copy; 2022 M3 Computing, LLC</p>
                    </div>
                </Container>
            </footer>
        </>
    )
}