import {createRoot} from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Consulting from "./components/consulting/Consulting";
import Development from "./components/development/Development";
import Education from "./components/education/Education";

const container = document.getElementById('root')
const root = createRoot(container!);

root.render(
    <>
        <Header/>
        <div className="cv-section" id="home">
                <Home/>
        </div>
        <div className="cv-section" id="consulting">
                <Consulting/>
        </div>
        <div className="cv-section" id="development">
            <Development/>
        </div>
        <div className="cv-section" id="education">
                <Education/>
        </div>
        <Footer/>
    </>
)

