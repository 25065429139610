import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import CarouselItem from "react-bootstrap/CarouselItem";
import "./Carousel.css"

export default function Consulting(){
    return (
        <>
            <Container>
                <div className="section">
                    <h1>Consulting</h1>
                    <Container>
                        <Carousel>
                            <CarouselItem>
                                <Card className="carousel-card text-center">
                                    <Card.Body>
                                        <Card.Title as="h2">Microservices Architectures</Card.Title>
                                        <Card.Text>
                                            We have designed countless microservices based systems, both as a replacement
                                            for monolithic applications as well as from scratch. We specialize in cloud
                                            native, microservices based systems. We cover the whole spectrum of concerns
                                            from CI/CD to inter-service communications. Let us help you move to the latest
                                            architectural style.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CarouselItem>
                            <CarouselItem>
                                <Card className="carousel-card text-center">
                                    <Card.Body>
                                        <Card.Title as="h2">Cloud Migrations</Card.Title>
                                        <Card.Text>
                                            Let us help you design a strategy for migrating your existing applications to a
                                            public or private cloud infrastructure. We can help you plan your strategy,
                                            document application migrations, and educate your teams. We have years of
                                            experience with public and private cloud based systems, including hybrid and
                                            multi-cloud offerings.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CarouselItem>
                            <CarouselItem>
                                <Card className="carousel-card text-center">
                                    <Card.Body>
                                        <Card.Title as="h2">Small Business IT</Card.Title>
                                        <Card.Text>
                                            Because we are nimble in nature, no job is too big or too small. Let us help you
                                            focus on your business, while we take care of the technical needs you may have. We
                                            can help you setup your office network, office technologies, or payment systems. We
                                            can also help you with your application and database needs.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CarouselItem>
                            <CarouselItem>
                                <Card className="carousel-card text-center">
                                    <Card.Body>
                                        <Card.Title as="h2">Data Design</Card.Title>
                                        <Card.Text>
                                            Do you have your application needs solved, but need help with data modeling and
                                            design? We have experiences with everything from small relational database needs
                                            to large scale NoSql offerings. Let us help you with the design so you can focus
                                            on your business.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CarouselItem>
                        </Carousel>
                    </Container>
                </div>
                <div>
                    <h3>Our philosophy</h3>
                    <p>
                        We believe in building relationships, not simply focusing on the bottom line. We want your referral
                        and your trust, and will do everything in our power to earn it. This philosophy extends beyond
                        our mission, it is an integral part of who we are.
                    </p>
                    <h3>Mission alignment</h3>
                    <p>
                        We achieve our relationship goals by first understanding your mission. If we cannot buy into your
                        mission, we won't take on any work. This removes us from several prospects, but it allows us to
                        focus on you, because we can see your mission as valuable and worthy of achieving. By leveraging
                        your mission in our focus, we don't just consult, we become an extension of your team.
                    </p>
                </div>
            </Container>
        </>
    )
}