import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import "./Development.css"

export default function Development(){
    return (
        <>
            <Container className="section">
                <h1>Development</h1>
                <p>
                    We are engineers first, and architects and consultants after that. As such, development is where we
                    really shine, but of course good development takes strong strategies based on core principles and
                    patterns.
                </p>
                <Row>
                    <h3>API Development</h3>
                    <Col md={4}>
                        <Card className="dev-card">
                            <Card.Body>
                                <Card.Text>
                                    <ListGroup variant="flush">
                                        <ListGroupItem>REST API Development</ListGroupItem>
                                        <ListGroupItem>GraphQL API Development</ListGroupItem>
                                        <ListGroupItem>SOAP Replacements</ListGroupItem>
                                    </ListGroup>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={8}>
                        <p>
                            APIs are our specialty, especially when it comes to cloud native service design and development.
                            While we strongly believe in microservices based architectures, we have built many monolithic
                            systems as well. Allow us to augment your team or take over an entire set of services. We focus
                            mainly on Java and Go based services, but are not limitted to those two languages.
                        </p>
                        <p>
                            Building these services is more than just the code, however. We don't deliver code that isn't
                            fully tested. We also believe in good documentation, and rely heavily on OpenAPI to deliver that
                            documentation.
                        </p>
                        <p>
                            We also believe that writing code for you, means you own the code. We will reserve the right
                            to use code we have created to accelerate development, and may augment our own libraries while
                            working for you, but you will have full access to the libraries in your own repos. We also
                            rely heavily on open source software, and may commit improvements to OSS libraries used for
                            your project.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <h3>Application Development</h3>
                    <Col md={8}>
                        <p>
                            Much of our work for smaller businesses is single application development.
                        </p>
                        <p>
                            Ideally we will leverage cloud based web applications where appropriate, but are not restricted
                            to that. We build everything from Mobile applications to Desktop applications as needed. In the
                            process we can help you secure the infrastructure you will need to run these applications on
                            all of your machines
                        </p>
                        <p>
                            We will never hand over an application that isn't tested or documented, and that work is included
                            in the quote we give you. We then hand you the code, and can either walk away or provide you
                            updates as needed. We are a truly full service application development company.
                        </p>
                    </Col>
                    <Col md={4}>
                        <Card className="dev-card">
                            <Card.Body>
                                <Card.Text>
                                    <ListGroup variant="flush">
                                        <ListGroupItem>Desktop</ListGroupItem>
                                        <ListGroupItem>Mobile</ListGroupItem>
                                        <ListGroupItem>Web</ListGroupItem>
                                    </ListGroup>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}