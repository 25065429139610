import React from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../assets/logo-long.svg"

export default function Home(){
    return (
        <>
            <Container>
                <div className="section">
                    <img src={Logo}
                         className="image-fluid"
                    />
                    <p className="fs-4">
                        M3 Computing is a Cloud Native Consultancy focused on cloud native architectures, education and
                        development needs for small to large businesses. Let us help you plan, design, develop, and deploy
                        your software needs.
                    </p>
                    <p className="fs-4">
                        Everything we do starts with education. From simply teaching your staff to actually walking through
                        the work we do, our primary emphasis is making sure you fully understand the work we are delivering.
                        We have experiences that span small and nimble start-ups to large scale enterprises.
                    </p>
                    <p className="fs-4">
                        Contact us today to let us help you with all your Cloud needs, to schedule a meeting, or to plan a
                        training event.
                    </p>
                </div>
            </Container>
        </>
    )
}